import React, { FC, useState, useCallback, useContext } from 'react';
import { Box, Typography, TextField, Button, Switch, FormControlLabel } from '@mui/material';
import axios from 'axios';
import { NavigationContext } from '../../hooks/navigation_provider';
import { AuthContext } from '../../hooks/auth_provider';

interface SettingsProps { }

const Settings: FC<SettingsProps> = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notifications, setNotifications] = useState(true);
  const auth = useContext(AuthContext);

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const handleNotificationsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNotifications(e.target.checked);
  }, []);

  const handleSave = useCallback(() => {
    // Implement save logic here
    console.log('Saving settings:', { email, password, notifications });
  }, [email, password, notifications]);

  const handleImportLocationHistory = useCallback(async () => {
    try {
      const response = await axios.post(
        'http://localhost:3000/api/import-location-history/',
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` }
        }
      );
      console.log(response.data.message);
      // Show a success message to the user
    } catch (error) {
      console.error('Error importing location history:', error);
      // Show an error message to the user
    }
  }, [auth.token]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ flexGrow: 1, overflow: 'auto', pt: 8, pb: 7 }}>
        <Box sx={{ maxWidth: 400, margin: 'auto', padding: 2 }} data-testid="Settings">
          <Typography variant="h4" gutterBottom>
            Account Settings
          </Typography>
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={handleEmailChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="New Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Switch
                checked={notifications}
                onChange={handleNotificationsChange}
                color="primary"
              />
            }
            label="Enable Notifications"
          />
          <Box sx={{ marginTop: 2 }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <Button variant="contained" color="primary" onClick={handleImportLocationHistory}>
              Import Google Maps Timeline History
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;

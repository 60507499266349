import './App.css';
import { RouterProvider } from "react-router-dom";
import { Router } from './routes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './hooks/auth_provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { InspirationProvider } from './hooks/inspiration_context';
import React, { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const createQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 1800
      }
    }
  })
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#4285F4',
    },
    secondary: {
      main: '#DB4437',
    },
    background: {
      default: '#f1f3f4',
    },
    text: {
      primary: '#202124',
      secondary: '#5f6368',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          textTransform: 'none',
        },
      },
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={createQueryClient()}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ""}>
        <AuthProvider>
          <InspirationProvider>
            <ThemeProvider theme={theme}>
              <div className="App">
                <Suspense fallback={<CircularProgress />}>
                  <RouterProvider router={Router} />
                </Suspense>
              </div>
            </ThemeProvider>
          </InspirationProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}